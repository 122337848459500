<template>
  <div v-if="service">
    <!-- <div class="p-4 w-sm max-w-sm">
      <div class="h-full border border-1 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
        <img class="h-32 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog">
        <div class="p-6">
          <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">{{service.name}}</h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">{{ service.title }}</h1>
          <p class="leading mb-3 h-vac-content">
            {{ service.domain }}
          </p>
          <div class="flex items-center flex-wrap">
        <v-btn small text @click="viewServiceDetails" color="secondary" class="inline-flex items-center md:mb-2 lg:mb-0">
              Learn More
              <svg class="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </v-btn>
            <span
              class="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
              <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"
                stroke-linejoin="round" viewBox="0 0 24 24">
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              1.2K
            </span>
            <span class="text-gray-400 inline-flex items-center leading-none text-sm">
              <svg class="w-4 h-4 mr-1" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"
                stroke-linejoin="round" viewBox="0 0 24 24">
                <path
                  d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z">
                </path>
              </svg>6
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <v-card flat class="auto elevation-4" height="100%">
      <v-card-text class="obj-title">
        <span class="title">{{ service.title }}</span>
        <v-btn x-small text color="accent" class="subaccent date-pub rounded-lg">bb{{ service.name }}</v-btn>
      </v-card-text>

      <v-card-text class="h-vac-content">{{ service.domain }}</v-card-text>
      <v-card-actions>
        <v-btn small outlined color="primary" class="subprimary" @click="viewServiceDetails">View</v-btn>
        <v-btn small outlined color="secondary" class="subsecondary" @click="EditService">Edit</v-btn>
        <v-btn small outlined color="error" class="suberror" @click="deleteService">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'
var md5 = require("md5")

export default {
  props: {
    service: {
      type: Object,
      default: () => { }
    },
  },

  methods: {

    formattedDate(datetime) {
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return { 'date': `${day}/${month}/${year}`, 'time': `${hours}:${minutes}` };
    },
    goToreferenceLink(value) {
      this.$router.push(link)
    },
    EditService() {
      this.$router.push({ name: 'company.service.edit', params: { company: this.$store.getters.getCompany.name, value: this.service.id, title: this.service.title } })
      // this.$router.push({ name: 'company.service.edit', params: { company: this.$store.getters.getCompany.name, value: this.service.id, title: md5(this.service.id + 'l') } })
    },
    viewServiceDetails() {
      this.$router.push({
        name: 'company.service.view', params: { company: this.$store.getters.getCompany.name, f_id: this.service.id, title: this.service.title }
        // name: 'company.service.view', params: { company: this.$store.getters.getCompany.name, f_id: this.service.id, title: md5(this.service.id + 'l')}
      })
    },
    deleteService() { }
  },
  mount() {
    console.log(this.Service)
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew
      },
    }
  },
}
</script>

<style lang="scss">
//@override
.v-card {
  border-radius: 1em !important;
}

.obj-title {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  flex-direction: row;
}

.obj-title .title {
  font-size: 11pt !important;
  color: var(--v-secondary-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.obj-title .date-pub {
  font-size: 8pt !important;
  margin-left: 4px !important;
  padding: 0px 3px !important;
}

.h-vac-content {
  height: 3em !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-vacancie {
  width: 100%;
  display: block;
}
</style>